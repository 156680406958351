import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  Typography,
  List,
  ListItem,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import API from '@/app/api/externalAPIs';
import Service from '@/app/utils/service';
import { JobType } from '@/app/components/Jobs/JobType';
import { shortTextFromHtml, HOMEPAGE, formatDate } from '@/app/utils/helper';
import { sanitize } from 'dompurify';
import translate from '@/app/utils/translate';
import { InputType } from '@/app/components/FormFields/FormFields';
import { throwError } from '@/app/redux/error';

export const getJobLocation = (job: JobType): string => {
  const { city, postalCode, countryCode } = job.location;
  if (!city || !postalCode || !countryCode) {
    return '';
  }

  return `${job.location.city} ${job.location.postalCode}, ${job.location.countryCode}`;
};

type JobListState = {
  jobs: JobType[];
  filteredJobs: JobType[];
};

type OwnProps = {
  isIsolated?: boolean;
};

type MapDispatchToProps = {
  throwError: typeof throwError;
};

type Props = OwnProps & MapDispatchToProps & RouteComponentProps;

class JobsList extends React.Component<Props, JobListState> {
  state: JobListState = {
    jobs: [],
    filteredJobs: [],
  };

  getJobsList(): void {
    Service.get(
      API.jobs,
      (data: any) => {
        const customFields: InputType[] = [];
        const currentDate = new Date();
        const jobs = data
          .map((jobData: any, index: number) => ({
            id: jobData.fJobOpeningId,
            title: jobData.fJobOpeningTitle,
            openingDate: jobData.fJobOpeningStartDate,
            endingDate: jobData.fJobOpeningEndDate
              ? jobData.fJobOpeningEndDate
              : '',
            attachmentRequired: jobData.fJobOpeningAttachmentIsMandatory,
            description: jobData.fJobOpeningDesc || '',
            customFields,
            isOpen: jobData.fJobOpeningIsOpen,
            order: jobData.fJobOpeningSeq || index,
            location: {
              city: jobData.fJobOpeningLocation.fJobOpeningCity,
              countryCode: jobData.fJobOpeningLocation.fJobOpeningCountry,
              postalCode: jobData.fJobOpeningLocation.fJobOpeningPostalCode,
            },
          }))
          .filter((job: JobType) => new Date(job.openingDate) <= currentDate)
          .sort((first: JobType, second: JobType) => {
            return first.order - second.order;
          });

        this.setState({ jobs });
        this.setState({ filteredJobs: jobs });
      },
      (error: any) => this.props.throwError(error),
    );
  }

  filterJobs(searchQuery: string): void {
    const { jobs } = this.state;
    const filteredJobs = jobs.filter(job => {
      const title = job.title.toLocaleLowerCase();
      const description = job.description.toLocaleLowerCase();
      const city = job.location.city.toLocaleLowerCase();
      const countryCode = job.location.countryCode.toLocaleLowerCase();
      const postCode = job.location.postalCode;
      const query = searchQuery.toLocaleLowerCase();

      return (
        title.includes(`${query}`) ||
        description.includes(`${query}`) ||
        city.includes(`${query}`) ||
        countryCode.includes(`${query}`) ||
        postCode.includes(`${query}`)
      );
    });

    this.setState({ filteredJobs });
  }

  componentDidMount() {
    this.getJobsList();
  }

  goToJobPage(jobId: number) {
    if (this.props.isIsolated) {
      return this.props.history.push(`${HOMEPAGE}/isolated/jobs/${jobId}`);
    }
    this.props.history.push(`${HOMEPAGE}/external/jobs/${jobId}`);
  }

  render() {
    return (
      <div>
        <Typography variant="h6" gutterBottom>
          {translate.t('laOpenPositions')}
        </Typography>
        <OutlinedInput
          margin="dense"
          placeholder={translate.t('laSearch')}
          labelWidth={0}
          fullWidth={true}
          onChange={e => {
            this.filterJobs(e.target.value);
          }}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
        <List component="nav">
          {this.state.filteredJobs.map((job: JobType) => (
            <ListItem
              button
              key={job.id}
              onClick={() => {
                this.goToJobPage(job.id);
              }}
              style={{ overflow: 'hidden' }}
            >
              <div>
                <Typography gutterBottom variant="subtitle1" noWrap>
                  {`${job.title}    ${formatDate(
                    job.openingDate,
                  )} - ${formatDate(job.endingDate)}`}
                </Typography>
                <Typography
                  variant="body1"
                  noWrap
                  color="textSecondary"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(shortTextFromHtml(job.description)),
                  }}
                />
                <Typography
                  variant="body1"
                  noWrap
                  color="textSecondary"
                  gutterBottom
                >
                  {getJobLocation(job)}
                </Typography>
              </div>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

export default connect(null, { throwError })(JobsList);
